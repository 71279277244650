import { MhcGeographyEnum } from "graphqlApi/types";

export const GeographyNumberValueEncoding: { [key in MhcGeographyEnum]: number } = {
  [MhcGeographyEnum.Sewershed]: 1,
  [MhcGeographyEnum.Neighborhood]: 2,
  [MhcGeographyEnum.HouseOfRepDistrict]: 3,
  [MhcGeographyEnum.SchoolDistrict]: 4,
  [MhcGeographyEnum.CensusTract]: 5,
  [MhcGeographyEnum.CensusBlockGroup]: 6,
  [MhcGeographyEnum.ZipCode]: 7,
  [MhcGeographyEnum.SenateDistrict]: 8,
  [MhcGeographyEnum.Municipality]: 9,
  [MhcGeographyEnum.County]: 10,
  [MhcGeographyEnum.State]: 11,
  [MhcGeographyEnum.Country]: 12
};

export const sortGeographiesBySizeFunction = (a: MhcGeographyEnum, b: MhcGeographyEnum): number => {
  if (GeographyNumberValueEncoding[a] < GeographyNumberValueEncoding[b]) {
    return -1;
  }
  if (GeographyNumberValueEncoding[a] > GeographyNumberValueEncoding[b]) {
    return 1;
  }
  return 0;
};

export const sortGeographiesBySize = (geos: MhcGeographyEnum[]) => {
  return geos.sort(sortGeographiesBySizeFunction);
};
